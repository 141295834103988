export const UserTypeMap = new Map<UserType, string>([
  ['DEPLOYER', '实施工程师'],
  ['MANAGER', '经营管理员'],
  ['RUNTIME', '普通用户'],
])

export const UserTypeTagColorMap = new Map<UserType, string>([
  ['DEPLOYER', 'orange'],
  ['MANAGER', 'blue'],
  ['RUNTIME', 'default'],
])

export const LoginLogMap = new Map<LoginLogType, string>([
  ['LOGIN_SUCCEED', '登录成功'],
  ['LOGIN_FAILED', '登录失败'],
  ['LOGOUT', '退出登录'],
])
